//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "color-picker",
  props: {
    drawer: {
      type: Boolean,
      default: false
    }
  }
};