var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pdf-preview-wrapper w-100"},[_c('v-layout',{staticClass:"header-top w-100"},[_c('v-flex',{staticClass:"file-name"},[_c('div',[_c('v-icon',{staticClass:"red--text",staticStyle:{"width":"54px","font-size":"34px"}},[_vm._v("mdi-file-pdf-outline")])],1),_c('div',{staticClass:"name mr-4"},[_c('span',[_vm._v("NLKMi66C3WtOslfQDT1F5UPJegqM1jDF14ahLR5m.pdf")]),_c('div',{staticClass:"size"},[_vm._v("91KB")])]),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","content-class":"custom-bottom-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"rename-btn ml-2",attrs:{"color":"blue","link":""}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])]}}])},[_c('span',{},[_vm._v("Rename")])]),_c('v-tooltip',{attrs:{"bottom":"","content-class":"custom-bottom-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"rename-btn ml-2",attrs:{"color":"blue","link":""}},'v-icon',attrs,false),on),[_vm._v("mdi-lock-open-outline")])]}}])},[_c('span',{},[_vm._v("Lock")])])],1)]),_c('v-spacer'),_c('v-flex',{staticClass:"file-actions d-flex justify-content-end"},[_c('div',{staticClass:"btn icon"},[_c('img',{attrs:{"src":"/media/pdf/printer.svg","alt":"","width":"24px","height":"24px"}})]),_c('div',{staticClass:"btn icon"},[_c('img',{attrs:{"src":"/media/pdf/share.svg","alt":"","width":"24px","height":"24px"}})]),_c('div',{staticClass:"btn txt"},[_c('img',{attrs:{"src":"/media/pdf/upload.svg","alt":"","width":"24px","height":"24px"}}),_c('span',[_vm._v("Export")])]),_c('div',{staticClass:"btn txt download"},[_c('img',{attrs:{"src":"/media/pdf/download.svg","alt":"","width":"24px","height":"24px"}}),_c('span',[_vm._v("Download")])])])],1),_c('v-layout',{staticClass:"d-flex pdf-header"},[_c('div',{staticClass:"header-lb d-flex"},[_c('v-tooltip',{attrs:{"right":"","content-class":"custom-right-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"document-view-icon svg-icon"},'div',attrs,false),on),[_c('img',{attrs:{"src":"/media/pdf/document-view.svg"}})])]}}])},[_c('span',{},[_vm._v("Document View")])]),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"page-view-icon svg-icon"},'div',attrs,false),on),[_c('img',{attrs:{"src":"/media/pdf/page-view.svg"}})])]}}])},[_c('span',{},[_vm._v("Page View")])]),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"thumbnail-view-icon svg-icon"},'div',attrs,false),on),[_c('img',{attrs:{"src":"/media/pdf/thumbnail-view.svg"}})])]}}])},[_c('span',{},[_vm._v("Thumbnail View")])])],1),_c('v-spacer'),_c('div',{staticClass:"header-mb d-flex"},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"svg-icon active"},'div',attrs,false),on),[_c('img',{attrs:{"src":"/media/pdf/pointer.svg"}})])]}}])},[_c('span',{},[_vm._v("Select (V)")])]),_c('v-menu',{attrs:{"bottom":"","offset-y":"","close-on-content-click":false,"content-class":"btns-submenu-wrapper","max-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"ml-2"},'div',attrs,false),on),[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"svg-icon"},'div',attrs,false),on),[_c('img',{attrs:{"src":"/media/pdf/pencil.svg"}})])]}}],null,true)},[_c('span',{},[_vm._v("Pencil (P)")])])],1)]}}])},[_c('div',{staticClass:"btns-submenu"},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var tooltips = ref.tooltips;
return [_c('div',_vm._g(_vm._b({staticClass:"row-1 d-flex"},'div',tooltips,false),on),[_c('div',{staticClass:"submenu-icon"},[_c('img',{attrs:{"src":"/media/pdf/thickness.svg","alt":""}})]),_c('div',{staticClass:"px-1",staticStyle:{"width":"160px"}},[_c('v-slider',{staticClass:"align-center",attrs:{"max":_vm.thickness_max,"min":_vm.thickness_min,"hide-details":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticStyle:{"min-width":"30px"}},[_vm._v(_vm._s(_vm.thickness_slider)+"px")])]},proxy:true}],null,true),model:{value:(_vm.thickness_slider),callback:function ($$v) {_vm.thickness_slider=$$v},expression:"thickness_slider"}})],1)])]}}])},[_c('span',{},[_vm._v("Thickness")])]),_c('div',{staticClass:"seprater"}),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var tooltips = ref.tooltips;
return [_c('div',_vm._g(_vm._b({staticClass:"row-1 d-flex"},'div',tooltips,false),on),[_c('div',{staticClass:"submenu-icon"},[_c('img',{attrs:{"src":"/media/pdf/opacity.svg","alt":""}})]),_c('div',{staticClass:"px-1",staticStyle:{"width":"160px"}},[_c('v-slider',{staticClass:"align-center",attrs:{"max":_vm.opacity_max,"min":_vm.opacity_min,"hide-details":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticStyle:{"min-width":"30px"}},[_vm._v(_vm._s(_vm.opacity_slider)+"%")])]},proxy:true}],null,true),model:{value:(_vm.opacity_slider),callback:function ($$v) {_vm.opacity_slider=$$v},expression:"opacity_slider"}})],1)])]}}])},[_c('span',{},[_vm._v("Opacity")])]),_c('div',{staticClass:"seprater"}),_c('div',{staticClass:"color-section d-flex flex-wrap"},[_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"white"})]),_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"black"})]),_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"green"})]),_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"red"})]),_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"blue"})]),_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"orange"})]),_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"blue"})])]),_c('div',{staticClass:"seprater"}),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"submenu-icon mx-0"},[_c('img',{attrs:{"src":"/media/pdf/plus.svg","alt":""}})]),_c('div',{staticClass:"blue--text cursor-pointer my-auto ml-1",on:{"click":function($event){_vm.color_drawer = true}}},[_vm._v(" Add custome color ")])])],1)]),_c('v-menu',{attrs:{"bottom":"","offset-y":"","close-on-content-click":false,"content-class":"btns-submenu-wrapper","max-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"ml-2"},'div',attrs,false),on),[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"svg-icon"},'div',attrs,false),on),[_c('img',{attrs:{"src":"/media/pdf/highlighter.svg"}})])]}}],null,true)},[_c('span',{},[_vm._v("Highlighter (H)")])])],1)]}}])},[_c('div',{staticClass:"btns-submenu"},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var tooltips = ref.tooltips;
return [_c('div',_vm._g(_vm._b({staticClass:"row-1 d-flex"},'div',tooltips,false),on),[_c('div',{staticClass:"submenu-icon"},[_c('img',{attrs:{"src":"/media/pdf/thickness.svg","alt":""}})]),_c('div',{staticClass:"px-1",staticStyle:{"width":"160px"}},[_c('v-slider',{staticClass:"align-center",attrs:{"max":_vm.thickness_max,"min":_vm.thickness_min,"hide-details":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticStyle:{"min-width":"30px"}},[_vm._v(_vm._s(_vm.thickness_slider)+"px")])]},proxy:true}],null,true),model:{value:(_vm.thickness_slider),callback:function ($$v) {_vm.thickness_slider=$$v},expression:"thickness_slider"}})],1)])]}}])},[_c('span',{},[_vm._v("Thickness")])]),_c('div',{staticClass:"seprater"}),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var tooltips = ref.tooltips;
return [_c('div',_vm._g(_vm._b({staticClass:"row-1 d-flex"},'div',tooltips,false),on),[_c('div',{staticClass:"submenu-icon"},[_c('img',{attrs:{"src":"/media/pdf/opacity.svg","alt":""}})]),_c('div',{staticClass:"px-1",staticStyle:{"width":"160px"}},[_c('v-slider',{staticClass:"align-center",attrs:{"max":_vm.opacity_max,"min":_vm.opacity_min,"hide-details":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticStyle:{"min-width":"30px"}},[_vm._v(_vm._s(_vm.opacity_slider)+"%")])]},proxy:true}],null,true),model:{value:(_vm.opacity_slider),callback:function ($$v) {_vm.opacity_slider=$$v},expression:"opacity_slider"}})],1)])]}}])},[_c('span',{},[_vm._v("Opacity")])]),_c('div',{staticClass:"seprater"}),_c('div',{staticClass:"color-section d-flex flex-wrap"},[_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"white"})]),_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"black"})]),_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"green"})]),_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"red"})]),_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"blue"})]),_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"orange"})]),_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"blue"})])]),_c('div',{staticClass:"seprater"}),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"submenu-icon mx-0"},[_c('img',{attrs:{"src":"/media/pdf/plus.svg","alt":""}})]),_c('div',{staticClass:"blue--text cursor-pointer my-auto ml-1",on:{"click":function($event){_vm.color_drawer = true}}},[_vm._v(" Add custome color ")])])],1)]),_c('v-menu',{attrs:{"bottom":"","offset-y":"","close-on-content-click":false,"content-class":"btns-submenu-wrapper","min-width":"200px","max-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"ml-2"},'div',attrs,false),on),[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"eraser-icon svg-icon"},'div',attrs,false),on),[_c('img',{attrs:{"src":"/media/pdf/eraser.svg"}})])]}}],null,true)},[_c('span',{},[_vm._v("Eraser (E)")])])],1)]}}])},[_c('div',{staticClass:"btns-submenu"},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var tooltips = ref.tooltips;
return [_c('div',_vm._g(_vm._b({staticClass:"row-1 d-flex"},'div',tooltips,false),on),[_c('div',{staticClass:"submenu-icon"},[_c('img',{attrs:{"src":"/media/pdf/thickness.svg","alt":""}})]),_c('div',{staticClass:"px-1",staticStyle:{"width":"160px"}},[_c('v-slider',{staticClass:"align-center",attrs:{"max":_vm.thickness_max,"min":_vm.thickness_min,"hide-details":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticStyle:{"min-width":"30px"}},[_vm._v(_vm._s(_vm.thickness_slider)+"px")])]},proxy:true}],null,true),model:{value:(_vm.thickness_slider),callback:function ($$v) {_vm.thickness_slider=$$v},expression:"thickness_slider"}})],1)])]}}])},[_c('span',{},[_vm._v("Thickness")])])],1)]),_c('v-menu',{attrs:{"bottom":"","offset-y":"","close-on-content-click":false,"content-class":"btns-submenu-wrapper","max-width":"200px","min-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"ml-2"},'div',attrs,false),on),[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"svg-icon"},'div',attrs,false),on),[_c('img',{attrs:{"src":"/media/pdf/text-highlight.svg"}})])]}}],null,true)},[_c('span',{},[_vm._v("Highlight Text (W)")])])],1)]}}])},[_c('div',{staticClass:"btns-submenu"},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var tooltips = ref.tooltips;
return [_c('div',_vm._g(_vm._b({staticClass:"row-1 d-flex"},'div',tooltips,false),on),[_c('div',{staticClass:"submenu-icon"},[_c('img',{attrs:{"src":"/media/pdf/opacity.svg","alt":""}})]),_c('div',{staticClass:"px-1",staticStyle:{"width":"160px"}},[_c('v-slider',{staticClass:"align-center",attrs:{"max":_vm.opacity_max,"min":_vm.opacity_min,"hide-details":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticStyle:{"min-width":"30px"}},[_vm._v(_vm._s(_vm.opacity_slider)+"%")])]},proxy:true}],null,true),model:{value:(_vm.opacity_slider),callback:function ($$v) {_vm.opacity_slider=$$v},expression:"opacity_slider"}})],1)])]}}])},[_c('span',{},[_vm._v("Opacity")])]),_c('div',{staticClass:"seprater"}),_c('div',{staticClass:"color-section d-flex flex-wrap"},[_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"white"})]),_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"black"})]),_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"green"})]),_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"red"})]),_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"blue"})]),_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"orange"})]),_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"blue"})])]),_c('div',{staticClass:"seprater"}),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"submenu-icon mx-0"},[_c('img',{attrs:{"src":"/media/pdf/plus.svg","alt":""}})]),_c('div',{staticClass:"blue--text cursor-pointer my-auto ml-1",on:{"click":function($event){_vm.color_drawer = true}}},[_vm._v(" Add custome color ")])])],1)]),_c('v-menu',{attrs:{"bottom":"","offset-y":"","close-on-content-click":false,"content-class":"btns-submenu-wrapper"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"ml-2"},'div',attrs,false),on),[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"svg-icon"},'div',attrs,false),on),[_c('img',{attrs:{"src":"/media/pdf/text.svg"}})])]}}],null,true)},[_c('span',{},[_vm._v("Text (T)")])])],1)]}}])},[_c('div',{staticClass:"btns-submenu"},[_c('div',{staticClass:"text-submenu d-flex"},[_c('v-menu',{attrs:{"bottom":"","offset-y":"","close-on-content-click":false,"content-class":"child-submenu-wrapper","min-width":"200px","max-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"font-family"},'div',attrs,false),on),[_c('div',[_vm._v("Times New Roman")])])]}}])},[_c('div',{staticClass:"select-font"},[_c('v-autocomplete',{attrs:{"hide-details":"","outlined":"","depressed":"","items":_vm.font_list},model:{value:(_vm.sdsdfg),callback:function ($$v) {_vm.sdsdfg=$$v},expression:"sdsdfg"}})],1)]),_c('v-menu',{attrs:{"bottom":"","offset-y":"","close-on-content-click":false,"content-class":"btns-submenu-wrapper","max-width":"200px","min-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"ml-2"},'div',attrs,false),on),[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var tooltips = ref.tooltips;
return [_c('div',_vm._g(_vm._b({staticClass:"row-1 d-flex mr-2"},'div',tooltips,false),on),[_c('div',{staticClass:"text-color-box",staticStyle:{"height":"40px","padding":"10px"}},[_c('div',{staticClass:"red"})])])]}}],null,true)},[_c('span',{},[_vm._v("Color")])])],1)]}}])},[_c('div',{staticClass:"btns-submenu"},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var tooltips = ref.tooltips;
return [_c('div',_vm._g(_vm._b({staticClass:"row-1 d-flex"},'div',tooltips,false),on),[_c('div',{staticClass:"submenu-icon"},[_c('img',{attrs:{"src":"/media/pdf/opacity.svg","alt":""}})]),_c('div',{staticClass:"px-1",staticStyle:{"width":"160px"}},[_c('v-slider',{staticClass:"align-center",attrs:{"max":_vm.opacity_max,"min":_vm.opacity_min,"hide-details":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticStyle:{"min-width":"30px"}},[_vm._v(_vm._s(_vm.opacity_slider)+"%")])]},proxy:true}],null,true),model:{value:(_vm.opacity_slider),callback:function ($$v) {_vm.opacity_slider=$$v},expression:"opacity_slider"}})],1)])]}}])},[_c('span',{},[_vm._v("Opacity")])]),_c('div',{staticClass:"seprater"}),_c('div',{staticClass:"color-section d-flex flex-wrap"},[_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"white"})]),_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"black"})]),_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"green"})]),_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"red"})]),_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"blue"})]),_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"orange"})]),_c('div',{staticClass:"color-box"},[_c('div',{staticClass:"blue"})])]),_c('div',{staticClass:"seprater"}),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"submenu-icon mx-0"},[_c('img',{attrs:{"src":"/media/pdf/plus.svg","alt":""}})]),_c('div',{staticClass:"blue--text cursor-pointer my-auto ml-1",on:{"click":function($event){_vm.color_drawer = true}}},[_vm._v(" Add custome color ")])])],1)]),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var tooltips = ref.tooltips;
return [_c('div',_vm._g(_vm._b({staticClass:"row-1 d-flex pr-1"},'div',tooltips,false),on),[_c('input',{staticClass:"input-size",attrs:{"type":"number","value":"12"}})])]}}])},[_c('span',{},[_vm._v("Size")])]),_c('v-menu',{attrs:{"bottom":"","offset-y":"","left":"","close-on-content-click":false,"content-class":"child-submenu-wrapper"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"ml-2"},'div',attrs,false),on),[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var tooltips = ref.tooltips;
return [_c('div',_vm._g(_vm._b({staticClass:"btn-icon"},'div',tooltips,false),on),[_c('img',{attrs:{"src":"/media/pdf/align-left.svg"}})])]}}],null,true)},[_c('span',{},[_vm._v("Alignment")])])],1)]}}])},[_c('div',{staticClass:"child-submenu d-flex p-2"},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var tooltips = ref.tooltips;
return [_c('div',_vm._g(_vm._b({staticClass:"btn-icon"},'div',tooltips,false),on),[_c('img',{attrs:{"src":"/media/pdf/align-left.svg"}})])]}}])},[_c('span',{},[_vm._v("Align Left")])]),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var tooltips = ref.tooltips;
return [_c('div',_vm._g(_vm._b({staticClass:"btn-icon"},'div',tooltips,false),on),[_c('img',{attrs:{"src":"/media/pdf/align-left.svg"}})])]}}])},[_c('span',{},[_vm._v("Align Center")])]),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var tooltips = ref.tooltips;
return [_c('div',_vm._g(_vm._b({staticClass:"btn-icon"},'div',tooltips,false),on),[_c('img',{attrs:{"src":"/media/pdf/align-left.svg"}})])]}}])},[_c('span',{},[_vm._v("Align Right")])])],1)])],1)])]),_c('v-menu',{attrs:{"bottom":"","offset-y":"","close-on-content-click":false,"content-class":"btns-submenu-wrapper","min-width":"200px","max-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"ml-2"},'div',attrs,false),on),[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"svg-icon"},'div',attrs,false),on),[_c('img',{attrs:{"src":"/media/pdf/shape.svg"}})])]}}],null,true)},[_c('span',{},[_vm._v("Shape")])])],1)]}}])},[_c('div',{staticClass:"btns-submenu"},[_c('div',{staticClass:"spapes-wrapper"},[_c('div',{staticClass:"d-flex py-2"},[_c('div',{staticClass:"menu-icon mr-2"},[_c('img',{attrs:{"src":"/media/pdf/shape.svg","alt":""}})]),_c('div',[_vm._v("Rectangle")])]),_c('div',{staticClass:"d-flex py-2"},[_c('div',{staticClass:"menu-icon mr-2"},[_c('img',{attrs:{"src":"/media/pdf/ellipse.svg","alt":""}})]),_c('div',[_vm._v("Ellipse")])]),_c('div',{staticClass:"d-flex py-2"},[_c('div',{staticClass:"menu-icon mr-2"},[_c('img',{attrs:{"src":"/media/pdf/line.svg","alt":""}})]),_c('div',[_vm._v("Line")])]),_c('div',{staticClass:"d-flex py-2"},[_c('div',{staticClass:"menu-icon mr-2"},[_c('img',{attrs:{"src":"/media/pdf/arrow.svg","alt":""}})]),_c('div',[_vm._v("Arrow")])])])])]),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"svg-icon"},'div',attrs,false),on),[_c('img',{attrs:{"src":"/media/pdf/insert-image.svg"}})])]}}])},[_c('span',{},[_vm._v("Insert Image (I)")])])],1),_c('v-spacer'),_c('div',{staticClass:"header-rb d-flex"},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"undo-icon svg-icon"},'div',attrs,false),on),[_c('img',{attrs:{"src":"/media/pdf/undo.svg"}})])]}}])},[_c('span',{},[_vm._v("Undo")])]),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"svg-icon"},'div',attrs,false),on),[_c('img',{attrs:{"src":"/media/pdf/redo.svg"}})])]}}])},[_c('span',{},[_vm._v("Redo")])]),_c('div',{staticClass:"svg-icon"},[_c('img',{attrs:{"src":"/media/pdf/search.svg"}})])],1)],1),_c('v-layout',{staticClass:"pdf-body"},[_c('v-flex',{staticClass:"sidebar"},[_c('div',{staticClass:"sidebar-wrapper"},[_c('div',{staticClass:"page-card"},[_c('div',{staticClass:"page-card-img"},[_c('img',{attrs:{"src":"media/pdf/preview-img.jpg","alt":""}})])]),_c('div',{staticClass:"insert-cart"},[_c('v-icon',{staticStyle:{"font-size":"50px"},attrs:{"color":"blue darken-4 pb-3"}},[_vm._v("mdi-file-plus-outline")]),_c('div',{staticClass:"txt-blue"},[_vm._v("Insert")]),_c('div',{staticClass:"txt"},[_vm._v("or drag & drop")])],1)])]),_c('v-flex',{staticClass:"content"},[_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"content-inner"},[_c('img',{attrs:{"src":"/media/pdf/preview-img.jpg","alt":""}})]),_c('div',{staticClass:"pdf-pagination"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"btns"},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"prev"},'div',attrs,false),on),[_c('img',{attrs:{"src":"/media/pdf/menu-left.svg","alt":""}})])]}}])},[_c('span',{},[_vm._v("Previous Page")])]),_c('div',{staticClass:"number"},[_c('span',[_vm._v("1")])]),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"next"},'div',attrs,false),on),[_c('img',{attrs:{"src":"/media/pdf/menu-left.svg","alt":""}})])]}}])},[_c('span',{},[_vm._v("Next Page")])])],1),_c('div',{staticClass:"txt"},[_c('span',[_vm._v("of 1")])])]),_c('div',{staticClass:"right"},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"fit-to-width"},'div',attrs,false),on),[_c('img',{attrs:{"src":"/media/pdf/fit-to-width.svg","alt":""}})])]}}])},[_c('span',{},[_vm._v("Fit to width")])]),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"zoom-out"},'div',attrs,false),on),[_c('img',{attrs:{"src":"/media/pdf/zoom-out.svg","alt":""}})])]}}])},[_c('span',{},[_vm._v("Zoom out")])]),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip tooltip-text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"zoom-in"},'div',attrs,false),on),[_c('img',{attrs:{"src":"/media/pdf/zoom-in.svg","alt":""}})])]}}])},[_c('span',{},[_vm._v("Zoom In")])])],1)])])])],1)],1),_c('ColorPicker',{attrs:{"drawer":_vm.color_drawer},on:{"close":function($event){_vm.color_drawer = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }